html, body {
  overflow: hidden; 
  position: relative;
  background: #010101;
}

*{
  box-sizing: border-box;
}

#root {
  height: calc(var(--vh, 1vh) * 100);
  font-family: "Avenir-Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow: hidden;
}

button {
  font-family: "Avenir-Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

button:focus {
  outline: none;
}

.lightbox__inner {
  display: block; 
  width: 100%; 
}

.lightbox__inner._r { 
  display: flex;
  align-items: center; 
  justify-content: center;
}

.lightbox__inner .content-right,
.lightbox__inner .img-left { 
  display: inline-block; 
  width: 50%; 
  vertical-align: middle;
}

.lightbox__inner .content-right {
  padding: 20px;
}

@font-face {
    font-family: 'Avenir-Book';
    src:
        url('./assets/fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Avenir-Book.woff') format('woff'),
        url('./assets/fonts/Avenir-Book.ttf')  format('truetype'),
        url('./assets/fonts/Avenir-Book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Light';
    src:
        url('./assets/fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Avenir-Light.woff') format('woff'),
        url('./assets/fonts/Avenir-Light.ttf')  format('truetype'),
        url('./assets/fonts/Avenir-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MinionPro-Regular';
    src:
        url('./assets/fonts/MinionPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/MinionPro-Regular.otf')  format('opentype'),
        url('./assets/fonts/MinionPro-Regular.woff') format('woff'),
        url('./assets/fonts/MinionPro-Regular.ttf')  format('truetype'),
        url('./assets/fonts/MinionPro-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
